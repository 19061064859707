import React from 'react';
import img2 from './assets/sandringham.png';
import img1 from './assets/tww.png';
import img3 from './assets/jc.png';
import img4 from './assets/sheri.ai.png';
type Props = {
  img: string
};



function PortfolioItem({img}: Props) {

  return (
    <div className="mx-auto drop-shadow-md">
      {img === '1' &&
       <div className=' drop-shadow-xl'>
         <img src={img1} alt=""/> 
          <div className='min-h-[150px] py-4 border-t'>
            <a href="https://those-who-wander-test.web.app/home" className='underline text-xl pt-8 pb-2 '>TWW Book Club</a>
            <div className='font-thin	'>Corporate site for a financial services company, designed and created by myself. SEO optimised, with a blog and backend admin application.</div>
          </div>

          <span className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-white bg-green-700 m-1">
            Firebase
          </span>          
          <span className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-white bg-green-700 m-1">
            Angular
          </span>
          <span className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-white bg-green-700 m-1">
            SEO
          </span>
          <span className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-white bg-green-700 m-1">
            Design
          </span>
          </div>
       }
      {img === '2' && 
        <div className=''>
          <img  src={img2} alt=""/> 
          <div className='min-h-[150px] py-4'>
            <a href="https://sandringham-darlington.co.uk/" className='underline text-xl pt-8 pb-2 '>Sandringham Darlington</a>
            <div className='font-thin	'>Corporate site for a financial services company, designed and created by myself. SEO optimised, with a blog and backend admin application.</div>
          </div>
          <span className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-white bg-green-700 m-1">
            Angular
          </span>
          <span className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-white bg-green-700 m-1">
            SEO
          </span>
          <span className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-white bg-green-700 m-1">
            Design
          </span>
        </div>
      }
      {img === '3' && 
        <div className=''>
          <img src={img3} alt=""/> 
          <div className='min-h-[150px] py-4'>
            <a href="https://jcmchugh.com/" className='underline text-xl pt-8 pb-2 '>Jo McHugh</a>
            <div className='font-thin	'>Light site for an online eduction consultant to start thier online presence.</div>
          </div>
          <span className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-white bg-green-700 m-1">
            Angular
          </span>
          <span className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-white bg-green-700 m-1">
            Design
          </span>
          </div>
      }
      {img === '4' && 
        <div className=''>
          <img src={img4} alt=""/> 
          <div className='min-h-[150px] py-4'>
            <a href="https://sheri.ai/" className='underline text-xl pt-8 pb-2 '>sheri.ai</a>
            <div className='font-thin	'>AI powered application allowing users to question a large knowledge base and configure virtual agents. </div>
          </div>
          <span className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-white bg-green-700 m-1">
            Angular
          </span>
          <span className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-white bg-green-700 m-1">
            NodeJS
          </span>
          <span className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-white bg-green-700 m-1">
            AI
          </span>
          <span className="text-xs font-semibold inline-block py-1 px-2 rounded-full text-white bg-green-700 m-1">
            Design
          </span>
          </div>
      }
    </div>
  );
}

export default PortfolioItem;
