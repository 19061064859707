import React from 'react';
import DocumentMeta from 'react-document-meta';

const meta = {
  title: 'Alex Cormack-Widdop',
  description: 'Fullstack Software Engineer',
  canonical: '	https://cormackwiddop-com.web.app/static/media/sandringham.78d23add897ce1d1c741.png',
  image: '%PUBLIC_URL%logo.png',
  meta: {
    charset: 'utf-8',
    name: {
      keywords: 'react,meta,document,html,tags'
    }
  }
};

function App() {
  return (
  //  <DocumentMeta {...meta}>
   <div className='snap-start'>
      <header className='w-screen h-screen'>
        <div className=' flex  w-screen h-screen justify-center items-center p-4'>
          <div className='rounded-full h-[100px] w-[100px] bg-green-700'></div>
          <div className='ml-[-50px] mt-[16px] w-[360px]'>
            <h1 className='font-semibold	text-4xl font-medium '>Alex Cormack-Widdop</h1>
            <h2 className='font-normal'>Fullstack Software Engineer</h2>
          </div>
        </div>
        <svg className='animate-ping w-4 h-4 mx-auto mt-[-150px]' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
        </svg>
      </header>
    </div>
  // </DocumentMeta>
  );
}

export default App;
