import React from 'react';
import Image from './assets/bio.jpg';

function About() {
  return (
    <div className=" snap-start h-screen w-screen flex">
      <div className='m-auto w-[300px] flex-col space-around'>
        <img className='rounded-full h-[150px] w-[150px] m-auto' src={Image} alt="Bio"></img>
        <div className='text-center pt-4 text-xl font-thin'>Hello World!</div>
        <div className='text-center pt-2 text-lg font-normal py-2'>
          I'm an experienced & enthusiastic engineer, with a strong product mindset and broad experience. I'm always open to new challenges and opportunities!
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default About;
