import React from 'react';
import { SocialIcon } from 'react-social-icons';

function Contact() {
  return (
    <div className=" snap-start h-screen w-screen flex">
      <div className='inline-grid grid-cols-2 gap-4 m-auto'>
        <SocialIcon url="https://twitter.com/acormack93" bgColor="#15803d"/>
        <SocialIcon url="https://www.linkedin.com/in/alex-cormack-widdop-77938878/" bgColor="#15803d"/>
        <SocialIcon url="https://github.com/acormack93" bgColor="#15803d"/>
        <SocialIcon url="https://www.behance.net/alexcormackwiddop" bgColor="#15803d"/>
      </div>
    </div>
  );
}

export default Contact;
