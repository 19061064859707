import React from 'react';
import PortfolioItem from './PortfolioItem';

function Portfolio() {
  return (
    <div>
      <div className="snap-start h-screen w-screen">
        <h3 className='text-2xl sm:text-3xl p-10'>Portfolio</h3>
        <div className='relative w-full flex p-10 gap-6 md:gap-20 snap-x snap-mandatory overflow-x-auto space-around items-center'>
          <div className='w-full md:w-1/2 lg:w-1/2 max-w-[700px] h-[70vh] snap-center shrink-0 p-2'>
            <PortfolioItem img="4" />
          </div>
          <div className='w-full md:w-1/2 lg:w-1/2 max-w-[700px] h-[70vh] snap-center shrink-0 p-2'>
            <PortfolioItem img="2" />
          </div>
          <div className='w-full md:w-1/2 lg:w-1/2 max-w-[700px] h-[70vh] snap-center shrink-0 p-2'>
            <PortfolioItem img="1" />
          </div>
          <div className='w-full md:w-1/2 lg:w-1/2 max-w-[700px] h-[70vh] snap-center shrink-0 p-2'>
            <PortfolioItem img="3" />
          </div>
        </div>
        <svg className='animate-ping w-4 h-4 mx-auto mt-[-30px] sm:mt-[10px]' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 13l-7 7-7-7m14-8l-7 7-7-7" />
        </svg>
      </div>
    </div>


  );
}

export default Portfolio;
